import React from 'react'
import Layout from '@/components/Layout'
import Container from '@/components/Container'
import { graphql } from 'gatsby'
import Wysiwyg from '@/components/Wysiwyg'

interface NopageData {
  data: {
    wp: any
    instagram: any
    mainMenu: any
  }
}

const NoPageFound = ({ data }: NopageData) => {
  const { wp, instagram, mainMenu } = data
  mainMenu.navButton = wp?.navButtons || ''
  const langData = { languages: wp.languages, currentLang: 'fi', translations: [] }

  return (
    <Layout instagram={instagram} showhero={false} nav={mainMenu} lang={langData}>
      <Container defaultWidth={false} classNameInner="max-w-[800px]">
        <div className="grid justify-items-center md:mb-28 mb-20 mt-24 sm:mt-48">
          <h1>{wp.content.heading}</h1>
          <Wysiwyg classNameInner="text-center">{wp.content.content}</Wysiwyg>
        </div>
      </Container>
    </Layout>
  )
}

export default NoPageFound

export const HomePageQuery = graphql`
  query notfoundPage {
    mainMenu: wpMenu(locations: { in: MAIN_MENU }) {
      ...navigation
    }
    wp {
      ...allLang
      ...subscribe
      content: crbThemeOptions {
        content: gwNotfoundPageContentCrblang
        heading: gwNotfoundPageHeadingCrblang
      }
    }
    instagram: allInstagramContent(limit: 5) {
      ...instagram
    }
    locales: allLocale(filter: { language: { eq: "fi" } }) {
      ...stringTranslations
    }
  }
`
